import React from "react";
import { Link } from "gatsby";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import MarkdownBody from "../components/MarkdownBody";
import CareersBanner from "../components/CareersBanner";

export default () => (
  <>
    <SEO
      description="株式会社SocialDogのプライバシーポリシー。"
      title="プライバシーポリシー"
    />
    <PageHeader title="Privacy Policy" lead="プライバシーポリシー" />
    <Container>
      <MarkdownBody>
        <p>
          株式会社
          SocialDog（以下，「当社」といいます。）は，当社が提供するサービスやウェブサイト等（以下,「本サービス」といいます。）におけるプライバシー情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
        </p>

        <h3>第1条（プライバシー情報）</h3>
        <ul>
          <li>
            プライバシー情報のうち「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報を指します。
          </li>
          <li>
            プライバシー情報のうち「履歴情報および特性情報」とは，上記に定める「個人情報」以外のものをいい，ご利用いただいたサービスやご購入いただいた商品，ご覧になったページや広告の履歴，ユーザーが検索された検索キーワード，ご利用日時，ご利用の方法，ご利用環境，郵便番号や性別，職業，年齢，ユーザーのIPアドレス，クッキー情報，位置情報，端末の個体識別情報などを指します。
          </li>
        </ul>

        <h3>第２条（プライバシー情報の収集方法）</h3>
        <ul>
          <li>
            当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や，決済に関する情報を当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
          </li>
          <li>
            当社は，ユーザーについて，利用したサービスやソフトウエア，購入した商品，閲覧したページや広告の履歴，検索した検索キーワード，利用日時，利用方法，利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態，利用に際しての各種設定情報なども含みます），IPアドレス，クッキー情報，位置情報，端末の個体識別情報などの履歴情報および特性情報を，ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。
          </li>
        </ul>

        <h3>第３条（個人情報を収集・利用する目的）</h3>
        <p>当社が個人情報を収集・利用する目的は，以下のとおりです。</p>
        <ul class="child">
          <li>
            （1）ユーザーに自分の登録情報の閲覧や修正，利用状況の閲覧を行っていただくために，氏名，住所，連絡先，支払方法などの登録情報，利用されたサービスや購入された商品，およびそれらの代金などに関する情報を表示する目的
          </li>
          <li>
            （2）ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため，氏名や住所などの連絡先情報を利用する目的
          </li>
          <li>
            （3）ユーザーの本人確認を行うために，氏名，生年月日，住所，電話番号，銀行口座番号，クレジットカード番号，運転免許証番号，配達証明付き郵便の到達結果などの情報を利用する目的
          </li>
          <li>
            （4）ユーザーに代金を請求するために，購入された商品名や数量，利用されたサービスの種類や期間，回数，請求金額，氏名，住所，銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的
          </li>
          <li>
            （5）ユーザーが簡便にデータを入力できるようにするために，当社に登録されている情報を入力画面に表示させたり，ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的
          </li>
          <li>
            （6）代金の支払を遅滞したり第三者に損害を発生させたりするなど，本サービスの利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために，利用態様，氏名や住所など個人を特定するための情報を利用する目的
          </li>
          <li>
            （7）ユーザーからのお問い合わせに対応するために，お問い合わせ内容や代金の請求に関する情報など当社がユーザーに対してサービスを提供するにあたって必要となる情報や，ユーザーのサービス利用状況，連絡先情報などを利用する目的
          </li>
          <li>（8）上記の利用目的に付随する目的</li>
        </ul>

        <h3>第４条（個人情報の第三者提供）</h3>
        <ul>
          <li>
            当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
            <ul>
              <li>（1）法令に基づく場合</li>
              <li>
                （2）人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
              </li>
              <li>
                （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
              </li>
              <li>
                （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </li>
              <li>
                （5）予め次の事項を告知あるいは公表をしている場合
                <ol>
                  <li>利用目的に第三者への提供を含むこと</li>
                  <li>第三者に提供されるデータの項目</li>
                  <li>第三者への提供の手段または方法</li>
                  <li>
                    本人の求めに応じて個人情報の第三者への提供を停止すること
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            前項の定めにかかわらず，次に掲げる場合は第三者には該当しないものとします。
            <ul>
              <li>
                （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              </li>
              <li>
                （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合
              </li>
              <li>
                （3）個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いているとき
              </li>
            </ul>
          </li>
        </ul>

        <h3>第５条（個人情報の開示）</h3>
        <ul>
          <li>
            当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，１件あたり１，０００円の手数料を申し受けます。
            <ul>
              <li>
                （1）本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
              </li>
              <li>
                （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              </li>
              <li>（3）その他法令に違反することとなる場合</li>
            </ul>
          </li>
          <li>
            前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
          </li>
        </ul>

        <h3>第６条（個人情報の訂正および削除）</h3>
        <ul>
          <li>
            ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正または削除を請求することができます。
          </li>
          <li>
            当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正または削除を行い，これをユーザーに通知します。
          </li>
        </ul>

        <h3>第７条（個人情報の利用停止等）</h3>
        <p>
          当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行い，その結果に基づき，個人情報の利用停止等を行い，その旨本人に通知します。ただし，個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じます。
        </p>

        <h3>第８条（アクセス解析について）</h3>
        <p>
          本サービスにおいてはサービス向上のため Google, Inc. の GoogleAnalytics
          を利用してサイトの計測を行っております。
          これに付随して生成されるテキストファイル「Cookie」を通じて分析を行うことがありますが、この際、IPアドレス等のユーザ様情報の一部が、Google,
          Inc.に収集される可能性があります。サイト利用状況の分析、サイト運営者へのレポートの作成、その他のサービスの提供目的に限りこれを使用します。利用者は、本サイトを利用することで、上記方法および目的においてGoogleが行うこうしたデータ処理につき許可を与えたものとみなします。
        </p>
        <p>
          当サービス、または上記以外の第三者がユーザー情報を収集する場合があります。
          またウェブブラウザのDo Not Track設定は尊重しません。
        </p>

        <h3>第９条（プライバシーポリシーの変更）</h3>
        <ul>
          <li>
            本ポリシーの内容は，ユーザーに通知することなく，変更することができるものとします。
          </li>
          <li>
            当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
          </li>
        </ul>

        <h3>第１０条（お問い合わせ窓口）</h3>
        <p>
          本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
        </p>
        <p>
          株式会社 SocialDog
          <Link to="/contact/">お問い合わせフォーム</Link>
        </p>

        <p class="tR">以上</p>

        <p>2016年6月1日 制定</p>
        <p>2017年8月7日 改定</p>
        <p>2021年9月1日 改定</p>
      </MarkdownBody>
    </Container>
    <CareersBanner />
  </>
);
